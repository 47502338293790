<template>
  <g-card page-title="Mail Template List" page-icon="mdi-grid">
    <g-list-page
        :headers="headers"
        :actions="actions"
        :action-buttons="actionButton"
        stateendpoint="mailTemplate.mailTemplates"
        :show-add-button="false"
        :show-default-action-buttons="false"
        @loadData="loadData"
        @editItem="editItem"
        @deleteItem="deleteItem"
        @destroyItem="destroyItem"
        @switchHandler="switchHandler"
        @restoreItem="restoreItem"
    >
      <template v-slot:overlay>
        <page-overlay :overlay="isOverlay" />
      </template>
      <template v-slot:top>
        <v-row>
          <v-col cols="12" md="6" sm="8">
            <g-p-search
                hint="Minimum two characters needed to search. Columns(name)"
                @applySearch="handleSearch"
            />
          </v-col>
        </v-row>
      </template>
    </g-list-page>
    <component
        is="MailTemplateEditorPopup"
        ref="component"
        :item="editedItem"
        :show="showPopup"
        @closePopupHandler="closePopupHandler"
        @onPopupShow="openPopUp"
        @saveItemHandler="saveItemHandler"
    />
    <confirm-popup
        :show="showConfirm"
        confirm-event="onConfirmItem"
        @closePopupHandler="showConfirm = false"
        @onConfirmItem="confirmEvent(currentSelectedItem, true)"
    >
      <div>
        <!-- eslint-disable-next-line  -->
        <span>{{ confirmTxt }}</span>
      </div>
    </confirm-popup>
  </g-card>
</template>

<script>
import ListMixins from "../components/mixins/list/List";
import MailTemplateEditorPopup from "../components/popups/MailTemplateEditorPopup";
import GCard from "../components/GCard";
import GListPage from "../components/list/GListPage";
import PageOverlay from "../components/PageOverlay";
import GPSearch from "../components/GPSearch";
import ConfirmPopup from "../components/popups/ConfirmPopup";
export default {
  name: "MailTemplates",
  metaInfo: {
    title: "shantoitfirm.com",
    titleTemplate: "Admin Dashboard - Mail Templates | %s",
    meta: [
      { name: "robots", content: "noindex" },
      { name: "googlebot", content: "noindex" }
    ]
  },
  components: {
    ConfirmPopup,
    GPSearch,
    PageOverlay,
    GListPage,
    GCard,
    MailTemplateEditorPopup
  },
  mixins: [ListMixins],
  data() {
    return {
      headers: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Template Text',
          value: 'templateText'
        },
        {
          text: '',
          value: 'actions',
          sortable: false
        }
      ],
      actions: {
        load: 'mailTemplate/load',
        update: 'mailTemplate/update'
      },
      actionButton: [
        {
          category: 'edit',
          icon: 'mdi-pencil',
          color: '',
          text: 'Edit',
          clickHandler: 'editItem'
        }
       ]
    }
  },
}
</script>

<style scoped>

</style>